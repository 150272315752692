app.components.woocommerce_productfilter = {
    onReady: function () {
        if ($('.woocommerce-productfilter').length) {
            return true;
        }
    },

    addListener: function () {

        const that = this;

        Promise.all([
            import(/* webpackChunkName: "sticky-sidebar" */ 'sticky-sidebar'),
        ]).then((StickySidebarClass) => {

            const stickySidebar = StickySidebarClass[0].default;

            new stickySidebar('.woocommerce-productfilter', {
                topSpacing: parseInt($('header.header').height()),
                bottomSpacing: 0,
                containerSelector: '#woocommerce-products',
                innerWrapperSelector: 'form',
                resizeSensor: true,
                stickyClass: 'is-affixed'
            });
        });

        if (window.location.hash) {
            const hash = window.location.hash.replace('#', '');
            const cat = 'product_cat-' + hash;
            const tag = $(`input[name="${cat}"]`);
            if (tag.length) {
                tag.parents('ul').find('input').prop('checked', false);
                tag.prop('checked', 'checked');
                $(".product").hide();
                $("." + cat).show();
            }
        }

        // Handles the change event for product filter inputs
        $('.woocommerce-productfilter ul').each(function () {
            const productfilter = $(this);
            productfilter.on('change', 'input', function () {
                that.filter(productfilter);
            }).trigger('click');
        });
    },
    filter: function (productfilter) {
        let terms = [];
        const container = $('#woocommerce-products');
        let count = 0;
        $(".product", container).show();

        $('input', productfilter).each(function () {
            const $this = $(this);

            if ($this[0].checked) {
                $this.parents('.jcf-checkbox').removeClass('jcf-unchecked').addClass('jcf-checked');
                $this.prop('checked', true);
                count++;
            } else {
                $this.parents('.jcf-checkbox').removeClass('jcf-checked').addClass('jcf-unchecked');
                $this.prop('checked', false);
            }
        });

        if (!count) {
            productfilter.find('.jcf-unchecked').removeClass('jcf-unchecked').addClass('jcf-checked').find('input').prop('checked', true);
        }

        $('.woocommerce-productfilter input').each(function () {
            const cat = $(this).attr('value');
            if (!$(this)[0].checked) terms.push(cat);
        });

        $.each(terms, function (index, value) {
            $(".product." + value, container).hide();
        });

    }
}
