app.components.woocommerce_forms = {
    onReady: function () {
        if ($('body.woocommerce, body.woocommerce-page').length) {
            return true;
        }
    },

    addListener: function () {

        $(document).on('click', '.woocommerce-notices-wrapper', function () {
            $(this).fadeOut(function () {
                $(this).html('');
                $(this).attr('style', '')
            });
        });

        const container = $('.woocommerce form');

        Promise.all([
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.radio.js'),
            import(/* webpackChunkName: "jcf" */ 'jcf/dist/js/jcf.checkbox.js')
        ]).then(() => {
            jcf.replaceAll(container);
        }).catch(error => {
            console.error('Error loading modules:', error);
        });

        $('input, textarea, select', container).on('input change', function () {
            var $field = $(this).closest('.form-row');
            if (this.value) {
                $field.addClass('field--not-empty');
            } else {
                $field.removeClass('field--not-empty');
            }
        }).trigger('input');

        $('input, textarea, select', container).on('focusin', function () {
            var $field = $(this).closest('.form-row');
            $field.addClass('field--not-empty');
        }).on('focusout', function () {
            $(this).trigger('input')
        });

        $('.wpforms-recaptcha-hidden').attr('area-hidden', 'true');

        $( document ).on(
            'change input',
            '.woocommerce-cart-form .cart_item .quantity input',
            function (){
                console.log(123);
                $('form.woocommerce-cart-form').trigger('submit');
            }
        );

    }
};

