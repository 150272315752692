app.components.woocommerce_notices = {
    onReady: function () {
        if ($('body.woocommerce, body.woocommerce-page').length) {
            return true;
        }
    },

    addListener: function () {
        $(document).on('click', '.woocommerce-notices-wrapper', function () {
            $(this).fadeOut(function () {
                $(this).html('');
                $(this).attr('style', '')
            });
        });
    }
};

